import "./typingText.css";
import React from "react";
import Typist from "react-typist";

const colors = {
  maroon: "#701a27", // accent
  blue: "#068D9D", // accent
  green: "#B5B967", // secondary
  platinum: "#E7E3E0", // prim
  white: "#FFFFFF", // prim
};

function TypingText() {
  const textsToRotate = [
    "Working With Data!",
    "Building ML Models!",
    "Exploring Data!",
    "Teaching Data Science!",
    "Creating Deep Learning Models!",
    "Collabing with SMEs!",
    "Creating Data Pipelines!",
    "Learning New Stuff!",
    "Making APIs!",
    "Data-driven Optimization!",
    "Tackling Models’ Performance Issues!",
  ];

  var rows = [];
  for (var i = 0; i < 100; i++) {
    rows.push(
      <span>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "2.5vw",
            color: colors.maroon,
          }}
        >
          {textsToRotate[i % textsToRotate.length]}
        </span>
        <Typist.Backspace
          count={textsToRotate[i % textsToRotate.length].length}
          delay={2000}
        />
      </span>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        margin: "auto",
      }}
    >
      <div style={{ fontWeight: "bold", fontSize: "2.5vw" }}>I Enjoy&nbsp;</div>
      <Typist
        avgTypingDelay={70}
        stdTypingDelay={100}
        cursor={{
          show: false,
        }}
      >
        {rows}
      </Typist>
    </div>
  );
}

export default TypingText;
